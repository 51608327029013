// no tabs
.noTabs {
  ul.contentTabs {
    display: none;
  }
}
// footer
.snFooterContainer {
  background-color: #395b99;
  ul {
    text-align: center;
    margin-top: 20px;
    a { color: #fff; }
  }
  #extendedFooter {
    a.buttonLink {
        color: #fff;
    }
    .footerContent {
      .heroPhotoElement img {
        border: none;
        float: left;
      }
    }
    .textBlockElement {
      float: left;
      h3 {
        background: transparent;
        font-size: 16px;
        color: #67c7ef;
        box-shadow: none;
        padding: 10px 0px;
      }
      table {
        border: none;
        td {
          a {
            color: #fff;
          }
        }
      }
    }
    .dataTable th, .statTable th, .stat_table th {
      background: transparent;
      color: #67c7ef;
      font-size: 14px;
    }
    .dataTable {
      tbody {
        td {
          background: transparent;
          text-align: left;
          a {color: #fff;}
        }
      }
      thead th.ajax_sort:after { display: none;}
    }
  }
}
